import React from 'react';
import {InfoSec,Heading, Subtitle , BoldText , List , LineText} from './InfoSection.elements'
import { Container } from '../../globalStyles'

const PrivacyPolicyCN = () => {
    return (
        <InfoSec lightBg={true}>
            <Container>
                <Heading>隐私政策</Heading>
                <Heading>导言</Heading>
                <Subtitle>Flashpro 是一款由个人（以下简称“我们”）提供的产品。 您在使用我们的服务时，我们可能会收集和使用您的相关信息。我们希望通过本《隐私政策》向您说明，在使用我们的服务时，我们如何收集、使用、储存和分享这些信息，以及我们为您提供的访问、更新、控制和保护这些信息的方式。 本《隐私政策》与您所使用的 Flashpro 服务息息相关，希望您仔细阅读，在需要时，按照本 《隐私政策》的指引，
                    作出您认为适当的选择。本《隐私政策》中涉及的相关技术词汇，我们尽量以简明扼要的表述，并提供进一步说明的链接，以便您的理解</Subtitle>
                <BoldText>您使用或继续使用我们的服务，即意味着同意我们按照本《隐私政策》收集、使用、储存和分享您的相关信息</BoldText>
                <BoldText>如对本《隐私政策》或相关事宜有任何问题，请通过henryll79powell@hotmail.com与我们联系。</BoldText>
                <Heading>1. 我们收集的信息</Heading>
                <Subtitle>我们或我们的第三方合作伙伴提供服务时，可能会收集、储存和使用下列与您有关的信息。如果您不提供相关信息，可能无法注册成为我们的用户或无法享受我们提供的某些服务，或者无法达到相关服务拟达到的效果。</Subtitle>
                <List>
                    <li><Subtitle><strong>个人信息,</strong> 您在注册账户或使用我们的服务时，向我们提供的相关个人信息，例如电话号码、电子邮件等。</Subtitle></li>
                    <li><Subtitle><strong>日志信息,</strong> 指您使用我们的服务时，系统可能通过cookies、标识符及相关技术收集的信息，包括您的设备信息、浏览信息、点击信息，并将该等信息储存为日志信息，为您提供个性化的用户体验、保障服务安全。您可以通过浏览器设置拒绝或管理cookie、标识符或相关技术的使用。</Subtitle></li>
                </List>
                <Heading>2. 信息的存储</Heading>
                <BoldText>2.1 信息存储的方式和期限</BoldText>
                    <List>
                        <li><Subtitle>我们会通过安全的方式存储您的信息，包括本地存储（例如利用APP进行数据缓存）、数据库和服务器日志。</Subtitle></li>
                        <li><Subtitle>一般情况下，我们只会在为实现服务目的所必需的时间内或法律法规规定的条件下存储您的个人信息。</Subtitle></li>
                    </List>
                <BoldText>2.2 信息存储的地域</BoldText>
                    <List>
                        <li><Subtitle>我们会按照法律法规规定，将境内收集的用户个人信息存储于中国境内。</Subtitle></li>
                        <li><Subtitle>目前我们不会跨境传输或存储您的个人信息。将来如需跨境传输或存储的，我们会向您告知信息出境的目的、接收方、安全保证措施和安全风险，并征得您的同意。</Subtitle></li>
                    </List>
                <BoldText>2.3 产品或服务停止运营时的通知</BoldText>
                    <List>
                        <li><Subtitle>当我们的产品或服务发生停止运营的情况时，我们将以推送通知、公告等形式通知您，并在合理期限内删除您的个人信息或进行匿名化处理，法律法规另有规定的除外。</Subtitle></li>
                    </List>
                <Heading>3. 信息安全</Heading>
                <Subtitle>我们使用各种安全技术和程序，以防信息的丢失、不当使用、未经授权阅览或披露。例如，在某些服务中，我们将利用加密技术（例如SSL）来保护您提供的个人信息。但请您理解，由于技术的限制以及可能存在的各种恶意手段，在互联网行业，即便竭尽所能加强安全措施，也不可能始终保证信息百分之百的安全。您需要了解，您接入我们的服务所用的系统和通讯网络，有可能因我们可控范围外的因素而出现问题。</Subtitle>
                <Heading>4. 我们如何使用信息</Heading>
                <Subtitle>我们可能将在向您提供服务的过程之中所收集的信息用作下列用途：</Subtitle>
                <List>
                    <LineText>向您提供服务；</LineText>
                    <LineText>在我们提供服务时，用于身份验证、客户服务、安全防范、诈骗监测、存档和备份用途，确保我们向您提供的产品和服务的安全性；</LineText>
                    <LineText>帮助我们设计新服务，改善我们现有服务；</LineText>
                    <LineText>使我们更加了解您如何接入和使用我们的服务，从而针对性地回应您的个性化需求，例如语言设定、位置设定、个性化的帮助服务和指示，或对您和其他用户作出其他方面的回应；</LineText>
                    <LineText>向您提供与您更加相关的广告以替代普遍投放的广告；</LineText>
                    <LineText>评估我们服务中的广告和其他促销及推广活动的效果，并加以改善；</LineText>
                    <LineText>软件认证或管理软件升级;</LineText>
                    <LineText>让您参与有关我们产品和服务的调查。</LineText>
                </List>
                <Heading>5. 信息共享</Heading>
                <Subtitle>目前，我们不会主动共享或转让您的个人信息至第三方，如存在其他共享或转让您的个人信息或您需要我们将您的个人信息共享或转让至第三方情形时，我们会直接或确认第三方征得您对上述行为的明示同意。</Subtitle>
                <Subtitle>为了投放广告，评估、优化广告投放效果等目的，我们需要向广告主及其代理商等第三方合作伙伴共享您的部分数据，要求其严格遵守我们关于数据隐私保护的措施与要求，包括但不限于根据数据保护协议、承诺书及相关数据处理政策进行处理，避免识别出个人身份，保障隐私安全。</Subtitle>
                <Subtitle>我们不会向合作伙伴分享可用于识别您个人身份的信息（例如您的姓名或电子邮件地址），除非您明确授权。</Subtitle>
                <Subtitle>我们不会对外公开披露所收集的个人信息，如必须公开披露时，我们会向您告知此次公开披露的目的、披露信息的类型及可能涉及的敏感信息，并征得您的明示同意。</Subtitle>
                <Subtitle>随着我们业务的持续发展，我们有可能进行合并、收购、资产转让等交易，我们将告知您相关情形，按照法律法规及不低于本《隐私政策》所要求的标准继续保护或要求新的控制者继续保护您的个人信息。</Subtitle>
                <Subtitle>另外，根据相关法律法规及国家标准，以下情形中，我们可能会共享、转让、公开披露个人信息无需事先征得您的授权同意：</Subtitle>
                <List>
                    <LineText>与国家安全、国防安全直接相关的；</LineText>
                    <LineText>与公共安全、公共卫生、重大公共利益直接相关的；</LineText>
                    <LineText>犯罪侦查、起诉、审判和判决执行等直接相关的；</LineText>
                    <LineText>出于维护个人信息主体或其他个人的生命、财产等重大合法权益但又很难得到本人同意的；</LineText>
                    <LineText>个人信息主体自行向社会公众公开个人信息的；</LineText>
                    <LineText>从合法公开披露的信息中收集个人信息的，如合法的新闻报道、政府信息公开等渠道。</LineText>
                </List>

                <Heading>6. 您的权利</Heading>
                <Subtitle>在您使用我们的服务期间，我们可能会视产品具体情况为您提供相应的操作设置，以便您可以查询、删除、更正或撤回您的相关个人信息，您可参考相应的具体指引进行操作。此外，我们还设置了投诉举报渠道，您的意见将会得到及时的处理。如果您无法通过上述途径和方式行使您的个人信息主体权利，您可以通过本《隐私政策》中提供的联系方式提出您的请求，我们会按照法律法规的规定予以反馈。</Subtitle>
                <Subtitle>当您决定不再使用我们的产品或服务时，可以申请注销账户。注销账户后，除法律法规另有规定外，我们将删除或匿名化处理您的个人信息。</Subtitle>

                <Heading>7. 变更</Heading>
                <Subtitle>我们可能适时修订本《隐私政策》的条款。当变更发生时，我们会在版本更新时向您提示新的《隐私政策》，并向您说明生效日期。请您仔细阅读变更后的《隐私政策》内容，<strong>若您继续使用我们的服务，即表示您同意我们按照更新后的《隐私政策》处理您的个人信息。</strong></Subtitle>

                <Heading>8. 未成年人保护</Heading>
                <Subtitle>我们鼓励父母或监护人指导未满十八岁的未成年人使用我们的服务。我们建议未成年人鼓励他们的父母或监护人阅读本《隐私政策》，并建议未成年人在提交的个人信息之前寻求父母或监护人的同意和指导。</Subtitle>
                <Heading>9. 社交账户信息收集</Heading>
                <Subtitle>我们的服务可能允许您使用社交媒体账户登录或连接。当您选择这样做时，我们可能会从您的社交媒体个人资料中收集信息，包括您的姓名、头像、电子邮件地址和其他公开可用的信息。这些信息用于增强您的体验和促进我们平台内的社交互动。</Subtitle>
                <Subtitle>我们还可能在您明确同意的情况下请求访问额外的社交媒体数据，例如您的好友列表或社交关系。这些信息用于我们服务内的社交功能，并受到您在社交媒体账户上建立的隐私设置的约束。</Subtitle>
                <Subtitle>我们不会未经您的许可发布或分享任何信息到您的社交媒体账户上。</Subtitle>
                <Heading>10. 账户删除</Heading>
                <BoldText>重要事项：</BoldText>
                <Subtitle>卸载或删除应用程序不会删除您的账户。</Subtitle>
                <Subtitle>当您删除账户时，所有相关数据也将被删除。</Subtitle>
                <Subtitle>如果您在我们的应用程序中注册了您的电子邮件地址，您将无法再使用它进行登录。</Subtitle>
                <Subtitle>即使您重新安装应用程序，您也无法转移您的账户，因为无法从已删除的账户恢复数据。</Subtitle>
                <Subtitle>删除账户的步骤：</Subtitle>
                <Subtitle>登入账号>点击个人资料选项卡 > 删除账户 > 确认。</Subtitle>


            </Container>
        </InfoSec>
    );
  }
  
  export default PrivacyPolicyCN