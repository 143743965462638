export const homeObjOne = {
    primary: true,
    lightBg: false,
    lightTopLine: true,
    lightText: true,
    lightTextDesc: true,
    topLine: '',
    headline: 'FlashPro - Smart Sports Partner',
    description:
      'FlashPro is a leading sports intelligence AI application dedicated to helping you quickly and easily obtain information about the sports events you care about. Whether youre a sports fan or an event fanatic, FlashPro has something for you',
    buttonLabel: 'Get Started Now!',
    imgStart: '',
    img: require('../../images/flashpro.png'),
    alt: 'Credit Card',
    start: ''
  };
  
  export const homeObjTwo = {
    primary: true,
    lightBg: false,
    lightTopLine: true,
    lightText: true,
    lightTextDesc: true,
    topLine: 'Main Features',
    headline: 'Real time Sport News',
    description:
      "Stay in the game with real-time sports news and event updates, ensuring you catch every pivotal moment.",
    // buttonLabel: 'Learn More',
    imgStart: '',
    img: require('../../images/img1.png'),
    alt: 'Vault',
    start: ''
  };
  
  export const homeObjThree = {
    primary: false,
    lightBg: true,
    lightTopLine: false,
    lightText: false,
    lightTextDesc: false,
    topLine: 'Main Features',
    headline:
      'Main Features',
    description:
      "1. Quickly check event information, including schedule, game results and live game information through question and answer.",
    description2:
      "2. Provide the latest sports news and event updates to ensure you don't miss any important moments.",
    description3:
      "3. Supports a variety of sports and leagues, covering football, basketball, tennis, baseball and other fields. ",
    description4:
      "4. It can be personalized according to your interests and preferences and recommend relevant events to you.",
    description5:
      "FlashPro is suitable for sports fans, event enthusiasts and individuals who require real-time event information. Whether you want to know the latest game progress or need to check the event results in time, FlashPro is your best partner!",
    // buttonLabel: 'View Details',
    imgStart: 'start',
    img: require('../../images/flashpro.png'),
    alt: 'Vault',
    start: 'true'
  };
  
  export const homeObjFour = {
    primary: false,
    lightBg: true,
    lightTopLine: false,
    lightText: false,
    lightTextDesc: false, 
    topLine: 'Main Features',
    headline: 'Multiple Sports',
    description:
      'Covering a spectrum of sports and leagues – football, basketball, tennis, baseball, and more.',
    // buttonLabel: 'Sign Up Now',
    imgStart: 'start',
    img: require('../../images/img3.png'),
    alt: 'Vault',
    start: 'true'
  };
  export const homeObjFive = {
    primary: true,
    lightBg: false,
    lightTopLine: true,
    lightText: true,
    lightTextDesc: true,
    topLine: 'Main Features',
    headline: 'Personalized Contents',
    description:
      "Tailored experience – personalized recommendations based on your interests.",
    // buttonLabel: 'Learn More',
    imgStart: '',
    img: require('../../images/img4.png'),
    alt: 'Vault',
    start: ''
  };
  export const homeObjSix = {
    primary: false,
    lightBg: true,
    lightTopLine: false,
    lightText: false,
    lightTextDesc: false,
    topLine: 'Ultimate companion',
    headline:
      'DribblePro',
    description:
      "For sports enthusiasts, event aficionados, and anyone craving real-time event updates. Whether it's the latest game progress or instant event results, DribblePro is your ultimate smart companion!",
    // buttonLabel: 'View Details',
    imgStart: 'start',
    img: require('../../images/img5.png'),
    alt: 'Vault',
    start: 'true'
  };