export const homeObjOne = {
    primary: true,
    lightBg: false,
    lightTopLine: true,
    lightText: true,
    lightTextDesc: true,
    topLine: '',
    headline: 'Flashpro——智能体育伙伴',
    description:
      'FlashPro是一款领先的体育智能AI应用程序，致力于帮助您迅速轻松地获取关心的体育赛事信息。无论您是体育迷还是赛事狂热者，FlashPro都能满足您的需求。',
    buttonLabel: '下载！',
    imgStart: '',
    img: require('../../images/flashpro.png'),
    alt: '',
    start: ''
  };
  
  export const homeObjTwo = {
    primary: true,
    lightBg: false,
    lightTopLine: true,
    lightText: true,
    lightTextDesc: true,
    topLine: '主要特点',
    headline: '关键时刻',
    description:
      "通过实时体育新闻和事件更新，保持紧跟比赛，确保您捕捉到每一个关键时刻。",
    buttonLabel: '了解更多',
    imgStart: '',
    img: require('../../images/img1.png'),
    alt: 'Vault',
    start: ''
  };
  
  export const homeObjThree = {
    primary: false,
    lightBg: true,
    lightTopLine: false,
    lightText: false,
    lightTextDesc: false,
    topLine: '主要特点',
    headline:
      '主要功能',
    description:
      "1. 通过问答快速查看赛事信息，包括赛程、比赛结果和实时比赛信息。。",
    description2:
      "2. 提供最新的体育新闻和赛事更新，确保您不会错过任何重要时刻。",
    description3:
      "3. 支持多种体育和联赛，涵盖足球、篮球、网球、棒球等领域。。",
    description4:
      "4. 可根据您的兴趣和喜好进行个性化设置，并向您推荐相关赛事。",
    description5:
      "FlashPro适用于体育迷、赛事狂热者以及需要实时赛事信息的个人。无论您想了解最新的比赛进展还是及时查看赛事结果，FlashPro都是您最好的伙伴！",
    // buttonLabel: '查看详情',
    imgStart: 'start',
    img: require('../../images/flashpro.png'),
    alt: 'Vault',
    start: 'true'
  };
  
  export const homeObjFour = {
    primary: false,
    lightBg: true,
    lightTopLine: false,
    lightText: false,
    lightTextDesc: false, 
    topLine: '主要特点',
    headline: '多元化',
    description:
      '涵盖各种运动和联赛 – 足球、篮球、网球、棒球等。',
    buttonLabel: '立即注册',
    imgStart: 'start',
    img: require('../../images/img3.png'),
    alt: 'Vault',
    start: 'true'
  };
  export const homeObjFive = {
    primary: true,
    lightBg: false,
    lightTopLine: true,
    lightText: true,
    lightTextDesc: true,
    topLine: '主要特点',
    headline: '个性化推荐',
    description:
      "定制体验  – 根据您的兴趣提供个性化推荐。",
    // buttonLabel: 'Learn More',
    imgStart: '',
    img: require('../../images/img4.png'),
    alt: 'Vault',
    start: ''
  };
  export const homeObjSix = {
    primary: false,
    lightBg: true,
    lightTopLine: false,
    lightText: false,
    lightTextDesc: false,
    topLine: '终极智能伴侣',
    headline:
      'DribblePro',
    description:
      "对于体育爱好者、事件迷和渴望即时事件更新的任何人来说，无论是最新的比赛进展还是即时事件结果，DribblePro都是您的终极智能伴侣",
    // buttonLabel: 'View Details',
    imgStart: 'start',
    img: require('../../images/img5.png'),
    alt: 'Vault',
    start: 'true'
  };